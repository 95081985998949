import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/AmpLayout'
import Helmet from 'react-helmet'
import Content, { HTMLContent } from '../helper/Content'
import lang from '../helper/lang.json'
import useSiteMetadata from '../helper/SiteMetadata'

const ServicePage = ({ data, pageContext }) => {
  const { siteUrl } = useSiteMetadata()
  const { frontmatter, html } = data.index
  const { edges: posts } = data.services
  const { image } = frontmatter
  const PageContent = HTMLContent || Content
  const height = 10 / image.childImageSharp.fluid.aspectRatio
  const selectedLang = pageContext.lang

  const posts2 = posts.filter(elem => {
    return elem.node.frontmatter.lang === selectedLang
  })
  return (
    <Layout selectedLang={selectedLang}>
      <Helmet>
        <title>{'Dekko|' + frontmatter.title}</title>
      </Helmet>
      <div className="fixedblock">
        <amp-img
          alt="Mountains"
          width="10"
          height={`${height}`}
          layout="responsive"
          srcSet={
            !!image.childImageSharp
              ? `${siteUrl}${image.childImageSharp.fluid.srcSet}`
              : image
          }
          src={
            !!image.childImageSharp
              ? `${siteUrl}${image.childImageSharp.fluid.src}`
              : image
          }
        ></amp-img>
        <h1>{frontmatter.title}</h1>
        {posts2 &&
          posts2.map(({ node: post }, i) => (
            <div key={`div${i}`}>
              {post.frontmatter.image ? (
                <div key={`div2${i}`}>
                  <h2 key={`h2${i}`}>
                    <a
                      key={`a${i}`}
                      href={`${siteUrl}/amp${lang[selectedLang].slug +
                        post.fields.slug.slice(0, -3).substr(1)}`}
                    >
                      {' '}
                      {post.frontmatter.title}
                    </a>
                  </h2>
                  <amp-img
                    key={`img${i}`}
                    alt="Mountains"
                    width="10"
                    height={`${height}`}
                    layout="responsive"
                    srcSet={
                      !!post.frontmatter.image
                        ? `${siteUrl}${post.frontmatter.image.childImageSharp.fluid.srcSet}`
                        : post.frontmatter.image
                    }
                    src={
                      !!post.frontmatter.image
                        ? `${siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`
                        : post.frontmatter.image
                    }
                  ></amp-img>
                </div>
              ) : null}
            </div>
          ))}
        <PageContent className="content" content={html} />
      </div>
    </Layout>
  )
}

ServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      field: PropTypes.object,
      html: PropTypes.object,
      contentComponent: PropTypes.func,
    }),
  }),
}

export default ServicePage

export const pageQuery = graphql`
  query ServicePageAmp($id: String!) {
    index: markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 600, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
                src
                srcSet
            }
          }
        }
      }
    }
    services: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "service-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            lang
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            image {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`
